import React from "react";
import { Link } from "gatsby";
import { Row, Col } from "react-bootstrap";
import styled from "styled-components";
import BackgroundImage from "gatsby-background-image";
import { graphql, StaticQuery } from "gatsby";
import { Brands } from "../components/Branding";
import "../assets/css/App.css";
import "../assets/css/index.css";
import "../assets/css/Section.css";
import "../assets/css/Home.css";
import { Jumbotron } from "../components/Jumbotron";
import boiler from "../../static/Icons/boiler.png";
import pipe from "../../static/Icons/pipe.png";
import spanner from "../assets/Icons/spanner.png";
import plantroom from "../assets/Icons/plant-room.png"
import Contactform from "../components/ContactForm";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import Img from "gatsby-image";
import { RE } from "../components/Reviews";


import {
  MDBCarousel,
  MDBCarouselInner,
  MDBCarouselItem,
  MDBContainer,
} from "mdbreact";

const LeaveAReviewButton = styled.a`
  height: 100%;
`;




export default ({ data }) => (
  <Layout>
    <SEO
      title="Home"
      description="BBIS Commercial Heating Engineers London cover all aspects of heating &amp; hot water. Working with commercial heating systems &amp; domestic heating maintenance."
      keywords="central heating service london,central heating services london,london central heating services,boiler and central heating service
      ,boiler central heating service,central heating service providers,oil central heating service,central heating boiler service,gas central heating service,central
       heating service,central heating services,central heating systems london,gas central heating system installation,oil central heating system,gas central heating
        system,new central heating system,electric central heating systems,central heating system"
    />
    <div class="grey-segment text-left" id="home">
      <h1 class="heading-color pb-2">BBIS Commercial Heating is a company you can trust</h1>
      <p class="pb-4">
      We’ve grown rapidly over the last 50 years and established ourselves as specialists in the heating industry. Providing an efficient service with excellent customer satisfaction in and around London is what we do.
      </p>
      <Row>
        <Col sm className="home-button">
          <Link to="/commercial-boiler-servicing/">
            <img alt="Image of boiler" class="pb-4 mx-auto d-block home-icon" src={boiler} />
            <h3 class="heading-color text-center">Boiler Servicing</h3>
            <p class="text-center normal-text-colour">
            Book your annual commercial boiler service with us to keep your boiler running safely and efficiently 
            </p>
          </Link>
        </Col>
        <Col sm className="home-button">
          <Link to="/commercial-boiler-installations/">
            <img alt="Image of pipes" class="pb-4 mx-auto d-block home-icon" src={pipe} />
            <h3 class="heading-color text-center">Boiler Installations</h3>
            <p class="text-center normal-text-colour">
            We fit a wide range of boilers with warranties of up to 12 years
            </p>
          </Link>
        </Col>
        <Col sm className="home-button">
          <Link to="/commercial-plant-room-installation-london/" className="home-icon">
            <img alt="Image of spanner" class="pb-4 mx-auto d-block home-icon" src={plantroom} />
            <h3 class="heading-color text-center">Plant Rooms</h3>
            <p class="text-center normal-text-colour">Centralise your HVAC, plumbing and other systems to make maintenance and repair easier</p>
          </Link>
        </Col>
        <Col sm className="home-button">
          <Link to="/commercial-boiler-repair/" className="home-icon">
            <img alt="Image of spanner" class="pb-4 mx-auto d-block home-icon" src={spanner} />
            <h3 class="heading-color text-center">Boiler Repair</h3>
            <p class="text-center normal-text-colour">We’ll help you get your boiler back up and running</p>
          </Link>
        </Col>
      </Row>
      <hr></hr>
      <h2 className="heading-color">Take a look at some of our recently completed projects</h2>
      <p>
          We turned a dated boiler room installed in the 1970’s by the old GLC, where parts were obsolete for the ageing boilers and plant. The plant room was in dire need of modernisation.
      </p>
      <p>The renovation project involved various tasks aimed at enhancing the boiler room. It includes repairing the existing floor, as well as painting the walls, floor, and ceiling to give the space an updated look. Additionally, the installation of 2 new high efficiency condensing boilers and stainless steel flues was carried out, ensuring improved efficiency and durability. The project also encompasses the installation of necessary pipework and fittings, guaranteeing a seamless and reliable system. To optimise functionality, automatic controls and a distribution panel were installed, enabling efficient management and regulation of the boiler room.</p>
      <Row>
      <Col md className="pbs">
          <Img
            fluid={data.marblehouse.childImageSharp.fluid}
            alt="BBIS-Marble-House"
            className="pb-4 mx-auto d-block img-fluid"
          />
        </Col>

        <Col md className="pbs">
          <Img
            fluid={data.marblehouse1.childImageSharp.fluid}
            alt="BBIS-Marble-House"
            className="pb-4 mx-auto d-block img-fluid"
          />
        </Col>
        <Col md className="pbs">
          <Img
            fluid={data.marblehouse2.childImageSharp.fluid}
            alt="BBIS-Marble-House"
            className="pb-4 mx-auto d-block img-fluid"
          />
        </Col>

        <Col md className="pbs">
          <Img
            fluid={data.marblehouse3.childImageSharp.fluid}
            alt="BBIS-Marble-House"
            className="pb-4 mx-auto d-block img-fluid"
          />
        </Col>
      </Row>
      <br></br>
      <Row>
      <Col md className="pbs">
          <Img
            fluid={data.marblehouse4.childImageSharp.fluid}
            alt="BBIS-Marble-House"
            className="pb-4 mx-auto d-block img-fluid"
          />
        </Col>

        <Col md className="pbs">
          <Img
            fluid={data.marblehouse5.childImageSharp.fluid}
            alt="BBIS-Marble-House"
            className="pb-4 mx-auto d-block img-fluid"
          />
        </Col>
        <Col md className="pbs">
          <Img
            fluid={data.marblehouse6.childImageSharp.fluid}
            alt="BBIS-Marble-House"
            className="pb-4 mx-auto d-block img-fluid"
          />
        </Col>
      </Row>
      <hr></hr>
      <Row>
        <Col>
          <h3 className="heading-color">Looking for help with your commercial HVAC systems?</h3>
          <p>Our expert team can help you with all aspects of commercial heating, plumbing and more:</p>
          <li>Boiler installation</li>
          <li>Boiler servicing</li>
          <li>Boiler repair</li>
          <li>Power flushing</li>
          <li>Air conditioning</li>
          <li>Commercial stove and oven catering equipment</li>
          <li>Landlord Gas Safety Certificates</li>
          <li>Swimming pool boilers</li>
          <li>Commercial plant room installation and maintenance</li>
          <br></br>
          <p>Reach out to a team member or explore our website to learn more about our services.</p>
        </Col>
      </Row>
    </div>
    <div class="white-segment">
      <h4 class="sub-title">
      Our engineers have experience working with a range of manufacturers
      </h4>
      <Brands />
      <p class="pt-3 text-center">
      Call the office and speak to our friendly team to find out more about our gas, heating and plumbing services. {" "}
          <strong>
            <a href="tel:+448002289800">0800 2289800</a>
          </strong>
        </p>
    </div>
    <div class="grey-segment text-center">
      <h4 class="sub-title">London and Hertfordshire-based</h4>
      <h4 class="sub-title">
      Working with schools, offices, churches and other large commercial buildings
      </h4>
    </div>
    <div class="white-segment">
      <RE></RE>


      <div className="text-center">
        <LeaveAReviewButton
          href="https://www.google.com/search?source=hp&ei=K2EsXpvQJ4q5gwfV_bmQDA&q=bbis+heating&oq=bbis+h&gs_l=psy-ab.3.0.0l5j0i10j0.1046.2782..4133...0.0..0.53.283.6......0....1..gws-wiz.......0i131j0i3.EtmeFNs7Pcw#lrd=0x4876236a23cb8b5f:0x18544ae2e88f2398,1,,,"
          className="btn btn-primary btn-lg mx-1 my-4"
        >
          <i className="fa fa-thumbs-up" aria-hidden="true"></i> Leave a review
        </LeaveAReviewButton>
      </div>

      <h4 class="heading-color">Get in touch</h4>
      <p>
      Simply fill in your details and let us know your query below and we’ll get back to you as soon as possible.
      </p>
      <Contactform />
    </div>
  </Layout>
);
export const query = graphql`
  query indeximages {
    marblehouse: file(relativePath: { eq: "Projects/marblehouse/marblehouse.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(maxWidth: 945) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    newmarblehillhouse50: file(relativePath: { eq: "Projects/marblehouse/newmarblehillhouse.jpg" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid(maxWidth: 945) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    marblehouse1: file(relativePath: { eq: "Projects/marblehouse/marblehouse1.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(maxWidth: 945) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    marblehouse2: file(relativePath: { eq: "Projects/marblehouse/marblehouse2.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(maxWidth: 945) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    marblehouse3: file(relativePath: { eq: "Projects/marblehouse/marblehouse3.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(maxWidth: 945) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    marblehouse4: file(relativePath: { eq: "Projects/marblehouse/marblehouse4.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(maxWidth: 945) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    marblehouse5: file(relativePath: { eq: "Projects/marblehouse/marblehouse5.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(maxWidth: 945) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    marblehouse6: file(relativePath: { eq: "Projects/marblehouse/marblehouse6.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(maxWidth: 945) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  }
`;
